<template lang="">
  <div>
    <v-select
      :value="paymentMethod"
      :options="paymentMethodOptions"
      :clearable="false"
      :disabled="disabled"
      :placeholder="placeholder ?? $t('placeholderSelect')"
      :reduce="(val) => val"
      @input="(val) => $emit('update:paymentMethod', val)"
    >
      <template #option="{ label }">
        {{ $t(`packageConfig.${label}`) }}
      </template>

      <template #selected-option="{ label }">
        {{ $t(`packageConfig.${label}`) }}
      </template>
      <template #no-options>
        {{ $t('noOptions') }}
      </template>
    </v-select>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'

import store from '@/store'
import { env } from '@/libs/env'

export default {
  components: {
    vSelect: () => import('vue-select'),
  },
  props: {
    paymentMethod: {
      type: [String, null],
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const isAllowPaymentQR = computed(
      () => store.getters['userStore/getAllowPaymentQR'],
    )
    const paymentMethodOptions = computed(() => {
      let methods = ['CREDIT', 'MONEY']
      if (env.isHideCreditMethod) methods = methods.filter(method => method !== 'CREDIT')
      if (!isAllowPaymentQR.value) methods = methods.filter(method => method !== 'MONEY')
      return methods
    })
    return { paymentMethodOptions }
  },
}
</script>
<style lang=""></style>
