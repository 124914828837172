var render = function () {
  var _vm$placeholder;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-select', {
    attrs: {
      "value": _vm.paymentMethod,
      "options": _vm.paymentMethodOptions,
      "clearable": false,
      "disabled": _vm.disabled,
      "placeholder": (_vm$placeholder = _vm.placeholder) !== null && _vm$placeholder !== void 0 ? _vm$placeholder : _vm.$t('placeholderSelect'),
      "reduce": function reduce(val) {
        return val;
      }
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:paymentMethod', val);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(_ref) {
        var label = _ref.label;
        return [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(label))) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(_ref2) {
        var label = _ref2.label;
        return [_vm._v(" " + _vm._s(_vm.$t("packageConfig.".concat(label))) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }